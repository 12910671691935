import React from "react"
import ContentCard from "./ContentCard"
import styled from "styled-components"

const Block = styled.div`
  overflow: hidden;
`

const ContentBlock = ({ services }) => {
  const backgrounds = [0, 1, 2, 3]
  let currentIndex = 0

  while (true) {
    const backgroundIndex = backgrounds[currentIndex % backgrounds.length]
    services[currentIndex].backgroundIndex = backgroundIndex
    if (currentIndex === services.length - 1) {
      break
    }
    currentIndex++
  }

  return (
    <Block>
      {services.map((item, index) => {
        return (
          <ContentCard
            item={item}
            key={index}
            reverse={(index + 1) % 2 === 0}
            index={index}
            isLast={index === services.length - 1}
          />
        )
      })}
    </Block>
  )
}

export default ContentBlock
