import React, { useState } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import Button from "../Core/Button"
import { Row, Col, Container } from "styled-bootstrap-grid"
import Slider from "react-slick"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const HeroBlock = styled(Container)`
  height: 810px;
  margin-top: -81px;
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`

const RowStyled = styled(Row)`
  height: 100%;
`

const ColStyled = styled(Col)`
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  z-index: 10;
  &:first-of-type {
    border: none;
  }
`

const Overlay = styled.div`
  height: 810px;
  width: 100%;
  background: ${colors.black};
  opacity: 0.2;
  position: absolute;
  top: 0;
  @media (min-width: ${breakpoints.lg}) {
    height: 810px;
    z-index: 9;
  }
`

const Content = styled.div`
  position: absolute;
  bottom: 118px;
  left: 33px;
  z-index: 9;
  @media (min-width: ${breakpoints.lg}) {
    left: 50px;
    bottom: 61px;
  }
`

const Title = styled.div`
  ${fonts.avantGardeBold};
  font-size: 60px;
  color: ${colors.white};
  text-transform: lowercase;
`

const Text = styled.div`
  color: ${colors.white};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  ${fonts.avantGardeMedium};
  max-width: 337px;
  padding-bottom: 30px;
  padding-top: 17px;
  padding-right: 10px;
`

const ContentBlock = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    display: ${(props) => (props.isVisible ? "block" : "none")};
  }
`

const HeroCarousel = styled.div`
  margin-top: -81px;
  height: 810px;
  .slick-list {
    height: 810px;
  }
  .slick-slider {
    height: 810px;
  }
  .slick-dots {
    bottom: 48px;
    left: 33px;
    width: auto;
  }
  .slick-dots li.slick-active button:before {
    color: ${colors.white};
    opacity: 1;
    font-size: 8px;
  }
  .slick-dots li button:before {
    font-size: 8px;
    color: ${colors.white};
    margin: 0 16px 0 0;
    padding: 0;
    width: auto;
  }
  .slick-dots li {
    width: auto;
    height: auto;
    margin: 0;
  }
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`

const Background = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const BackgroundDesktop = styled(BackgroundImage)`
  height: 810px;
  width: 100%;
  position: absolute !important;
  transition: 0.5s;
`

const Slide = styled.div`
  position: relative;
  height: 832px;
  outline: none;
`

const BackgroundWrapper = styled.div`
  display: ${(props) => (props.show === "true" ? "block" : "none")};
`

const ContentMobile = styled.div`
  position: absolute;
  bottom: 118px;
  left: 33px;
  z-index: 9;
  max-width: 300px;
  height: 270px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: content-box;
`

const Hero = ({ data }) => {
  const [isVisible, setIsVisible] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)

  const changeVisibility = (index) => {
    setIsVisible(index)
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    fade: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    initialSlide: 0,
  }

  return (
    <React.Fragment>
      <HeroBlock fluid>
        {isLoaded && <Overlay />}
        {data.map((item, index) => {
          return (
            <BackgroundWrapper show={isVisible === index ? "true" : "false"} key={index}>
              {index === 0 ? (
                <BackgroundDesktop
                  fluid={item.image.fluid}
                  loading={"eager"}
                  onLoad={() => setIsLoaded(true)}
                  alt={item.image.title || item.title}
                />
              ) : (
                <BackgroundDesktop
                  fluid={item.image.fluid}
                  durationFadeIn={100}
                  alt={item.image.title || item.title}
                />
              )}
            </BackgroundWrapper>
          )
        })}
        <RowStyled>
          {data.map((item, index) => {
            return (
              <ColStyled
                md={12 / data.length}
                key={index}
                onMouseEnter={() => changeVisibility(index)}
              >
                <Content>
                  <Title>{item.title}</Title>
                  <ContentBlock isVisible={isVisible === index}>
                    <Text>{documentToReactComponents(item.content.json)}</Text>
                    <Button link={`${item.ctaLink}`} large={"true"}>
                      {item.ctaLabel}
                    </Button>
                  </ContentBlock>
                </Content>
              </ColStyled>
            )
          })}
        </RowStyled>
      </HeroBlock>
      <HeroCarousel>
        <Slider ref={(slider) => (slider = slider)} {...settings}>
          {data.map((item, index) => {
            const heroImage = item.mobileImage ? item.mobileImage : item.image
            return (
              <Slide key={index}>
                <Background
                  fluid={heroImage.fluid}
                  alt={heroImage.title || item.title}
                />
                <ContentMobile>
                  <Title>{item.title}</Title>
                  <ContentBlock isVisible={isVisible === index}>
                    <Text>{documentToReactComponents(item.content.json)}</Text>
                  </ContentBlock>
                  <Button link={`${item.ctaLink}`} large={"true"}>
                    {item.ctaLabel}
                  </Button>
                </ContentMobile>
                <Overlay />
              </Slide>
            )
          })}
        </Slider>
      </HeroCarousel>
    </React.Fragment>
  )
}

export default Hero
