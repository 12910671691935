import React from "react"
import { Col, Row, Container } from "styled-bootstrap-grid"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ContainerStyled = styled(Container)`
  position: relative;
  overflow: visible;
  padding-top: ${(props) =>
    props.isFirst
      ? "80px"
      : props.isSecond
      ? "90px"
      : props.isThird
      ? "80px"
      : props.isLast && "0"};
  margin-bottom: ${(props) => props.isLast && "50px"};
  @media (min-width: ${breakpoints.md}) {
    padding-top: ${(props) =>
      props.isFirst
        ? "140px"
        : props.isSecond
        ? "200px"
        : props.isThird
        ? "0"
        : props.isLast && "0"};
    padding-bottom: ${(props) =>
      props.isFirst
        ? "60px"
        : props.isSecond
        ? "320px"
        : props.isThird
        ? "150px"
        : props.isLast && "70px"};
    margin-bottom: ${(props) => props.isLast && "80px"};
  }

  @media (min-width: ${breakpoints.lg}) {
    padding-bottom: ${(props) => props.isSecond && "160px"};
    margin-bottom: ${(props) => props.isLast && "100px"};
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-bottom: ${(props) => props.isLast && "150px"};
  }
`

const ContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${breakpoints.md}) {
    order: ${(props) => (props.reverse ? 1 : 2)};
  }
`

const ImageCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    order: ${(props) => (props.reverse ? 2 : 1)};
  }
`

const Content = styled.div`
  padding: 0 33px;
  margin-bottom: 30px;
`

const ImageBlock = styled.div`
  padding: 0 66px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
    padding-bottom: 0;
    margin-right: auto;
    margin-top: ${(props) => props.isSecond && "120px"};
    max-height: none;
  }
  @media (min-width: ${breakpoints.xl}) {
    width: ${(props) => (props.isSecond || props.isLast) && "110%"};
    overflow: hidden;
  }
`

const ImgStyled = styled(Img)`
  width: 90%;
  @media (min-width: ${breakpoints.md}) {
    img {
      object-fit: cover !important;
    }
  }
`

const TitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${(props) =>
    props.isFirst
      ? "60px"
      : props.isSecond
      ? "81px"
      : props.isThird
      ? "60px"
      : props.isLast && "62px"};
  color: ${(props) => (props.isSecond ? colors.white : colors.blue)};
  @media (min-width: ${breakpoints.md}) {
    padding-top: 0;
  }
`

const SectionTitle = styled.div`
  font-size: 20px;
  letter-spacing: 0;
  line-height: 20px;
  ${fonts.avantGardeBold};
  margin-left: 15px;
`

const Title = styled.div`
  color: ${(props) => (props.isSecond ? colors.white : colors.blue)};
  ${fonts.avantGardeMedium};
  font-size: 32px;
  letter-spacing: 0;
  line-height: 42px;
  margin-top: 20px;
  margin-bottom: 40px;
  @media (min-width: ${breakpoints.md}) {
    max-width: 450px;
  }
`

const Background = styled(Img)`
  position: absolute !important;
`

const FirstBackground = styled(Background)`
  height: 308px;
  width: 261px;
  left: -124px;
  top: -15px;
  @media (min-width: ${breakpoints.sm}) {
    height: 308px;
    width: 261px;
    left: -114px;
    top: 115px;
  }

  @media (min-width: ${breakpoints.md}) {
    height: 631px;
    width: 530px;
    left: -230px;
    top: 74px;
  }
`

const SecondBackground = styled(Background)`
  height: 1105px;
  width: 100%;
  top: 100px;
  @media (min-width: ${breakpoints.md}) {
    height: 936px;
    width: 100%;
    top: 0;
    img {
      object-fit: unset !important;
    }
  }
`

const ThirdBackground = styled(Background)`
  height: 493px;
  width: 415px;
  right: -348px;
  top: 470px;
  @media (min-width: ${breakpoints.md}) {
    height: 631px;
    width: 530px;
    top: 0;
  }
`

const StyledContent = styled.div`
  ${fonts.avantGardeMedium};
  font-size: 16px;
  color: ${(props) => (props.isSecond ? colors.white : colors.grey)};
  letter-spacing: 0;
  line-height: 24px;
  p {
    margin-bottom: 12px;

    :last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: inside;
    margin: 15px 0;

    li {
      text-indent: -1.3em;
      p {
        display: inline;
      }
    }
  }
  margin-bottom: ${(props) =>
    props.isFirst
      ? "0"
      : props.isSecond
      ? "60px"
      : props.isThird
      ? "80px"
      : props.isLast && "81px"};

  h3 {
    ${fonts.avantGardeMedium};
    font-size: 12px;
    color: ${(props) => (props.isSecond ? colors.white : colors.grey)};
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  @media (min-width: ${breakpoints.md}) {
    h3 {
      @media (min-width: ${breakpoints.md}) {
        margin-bottom: 12px;
      }
    }
    margin-bottom: ${(props) =>
      props.isFirst
        ? "0"
        : props.isSecond
        ? "0"
        : props.isThird
        ? "0"
        : props.isLast && "0"};
  }
`

const Icon = styled.img`
  max-width: 48px;
  max-height: 48px;
`

const ContentCard = ({ item, reverse, index, isLast }) => {
  const { background1, background2, background3 } = useStaticQuery(
    graphql`
      query {
        background1: file(relativePath: { eq: "what-we-do/background1.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        background2: file(relativePath: { eq: "what-we-do/background2.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        background3: file(relativePath: { eq: "what-we-do/background3.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  const image = (
    <ImageCol
      md={6}
      lg={item.backgroundIndex === 0 || item.backgroundIndex === 2 ? 5 : 6}
      isLast={item.backgroundIndex === 3}
      reverse={reverse}
    >
      <ImageBlock
        isFirst={item.backgroundIndex === 0}
        isSecond={item.backgroundIndex === 1}
        isThird={item.backgroundIndex === 2}
        isLast={item.backgroundIndex === 3}
      >
        <ImgStyled
          fluid={item.image.fluid}
          isThird={item.backgroundIndex === 2}
          isSecond={item.backgroundIndex === 1}
        />
      </ImageBlock>
    </ImageCol>
  )

  const icon = item.icon

  const text = (
    <ContentCol
      md={6}
      lgOffset={
        item.backgroundIndex === 0 || item.backgroundIndex === 2 ? 1 : 0
      }
      isFirst={item.backgroundIndex === 0}
      isSecond={item.backgroundIndex === 1}
      isThird={item.backgroundIndex === 2}
      isLast={isLast}
      reverse={reverse}
    >
      <Content isLast={item.backgroundIndex === 3}>
        <TitleBlock
          isFirst={item.backgroundIndex === 0}
          isSecond={item.backgroundIndex === 1}
          isThird={item.backgroundIndex === 2}
          isLast={item.backgroundIndex === 3}
        >
          <Icon src={icon.file.url} alt={icon.title} />
          <SectionTitle isSecond={item.backgroundIndex === 1}>
            {item.category}
          </SectionTitle>
        </TitleBlock>
        <Title
          isFirst={item.backgroundIndex === 0}
          isSecond={item.backgroundIndex === 1}
          isThird={item.backgroundIndex === 2}
          isLast={item.backgroundIndex === 3}
        >
          {item.title}
        </Title>
        <StyledContent
          isFirst={item.backgroundIndex === 0}
          isSecond={item.backgroundIndex === 1}
          isThird={item.backgroundIndex === 2}
          isLast={item.backgroundIndex === 3}
        >
          {documentToReactComponents(item.content.json)}
        </StyledContent>
      </Content>
    </ContentCol>
  )

  const renderBackground = (index) => {
    switch (index) {
      case 0:
        return <FirstBackground fluid={background1.childImageSharp.fluid} />
      case 1:
        return <SecondBackground fluid={background2.childImageSharp.fluid} />
      case 2:
        return <ThirdBackground fluid={background3.childImageSharp.fluid} />
      case 3:
        return <div />
    }
  }

  return (
    <React.Fragment>
      <ContainerStyled
        id={item.title.toLowerCase()}
        fluid
        isFirst={item.backgroundIndex === 0}
        isSecond={item.backgroundIndex === 1}
        isThird={item.backgroundIndex === 2}
        isLast={isLast}
        index={index}
      >
        {renderBackground(item.backgroundIndex)}
        <Container>
          <Row>
            <>
              {image}
              {text}
            </>
          </Row>
        </Container>
      </ContainerStyled>
    </React.Fragment>
  )
}

export default ContentCard
