import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import Hero from "../components/WhatWeDo/Hero"
import UpNext from "../components/Core/UpNext"
import ContentBlock from "../components/WhatWeDo/ContentBlock"
import { graphql } from "gatsby"

const WhatWeDoPage = ({ location, data }) => {
  const path = location.pathname

  const { page } = data

  const { hero, services, upNext } = page

  return (
    <Layout hero={"true"}>
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        path={path}
      />
      <Hero data={hero} />
      <ContentBlock services={services} />
      <UpNext
        title={upNext.name}
        link={upNext.ctaLink}
        text={upNext.ctaLabel}
        image={upNext.image}
        alt={upNext.image.title || ""}
      />
    </Layout>
  )
}

export default WhatWeDoPage

export const pageQuery = graphql`
  query WhatWeDoQuery {
    page: contentfulWhatWeDoPage {
      metaTitle
      metaDescription
      hero {
        title
        content {
          json
        }
        ctaLabel
        ctaLink
        image {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }

        mobileImage {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }

      services {
        category
        title
        content {
          json
        }
        icon {
          file {
            url
          }
          title
        }
        image {
          fluid(quality: 100, maxWidth: 1442) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }

      upNext {
        image {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        ctaLabel
        ctaLink
        name
      }
    }
  }
`
